/**
 * Page Section
 */

.wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100%;

  .container {
    width: 100%;
  }

  .section_hero {
    flex: 0 0 auto;
  }

  .section_main {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .section_footer {
    flex: 0 0 auto;
  }
}
