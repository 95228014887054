/**
 * Image Box
 */

.imgbox {
  position: relative;
}

.imgbox_background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  object-fit: cover;
}
