/**
 * Card Page
 */

.card_index {
  box-shadow: none;
  transform: translate(0, 0);

  .card__title {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  .card__body {
    > * + * {
      margin-top: 1em;
    }

    > * + .meta {
      margin-top: 0.5em;
    }
  }

  .card__action {
    font-size: $font-size-sm;
    font-weight: font-weight('semi-bold');
    text-transform: uppercase;
    color: $secondary;
    transition: color $transition-duration $transition-timing-function;
  }

  code {
    color: $primary;
  }
}

.card_index:focus,
.card_index:hover {
  box-shadow: 0 2px 16px rgba($black, 0.1);
  transform: translate(0, -2px);

  .card__action {
    color: $primary;
  }
}

/**
 * Cards Media
 */

.card_index {
  .media {
    flex-direction: column;
  }

  .media__image {
    width: 100%;
    height: 150px;
    max-width: none;
  }

  .media__body {
    width: 100%;
  }
}

@include media-min('xs') {
  .card_index {
    .media {
      flex-direction: row;
    }

    .media__image {
      width: 150px;
      height: 150px;
      max-width: 30%;
      flex-shrink: 0;
    }

    .media__body {
      width: auto;
    }
  }
}

@include media-max('xs') {
  .card_index .media {
    > * + * {
      margin-top: $media-spacing;
      margin-left: 0;
      margin-right: 0;
    }
  }
}

/**
 * Card Background
 */

.card_background {
  text-align: center;
  transform: translate(0, 0);

  .card__title {
    display: block;
  }

  .card__body {
    > * + * {
      margin-top: 0.5em;
    }
  }

  .meta {
    justify-content: center;
  }

  code {
    color: $accent;
  }
}

.card_background:focus,
.card_background:hover {
  transform: translate(0, 0);
}
