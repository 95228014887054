/**
 * Card Overrides
 */

.card__title {
  font-weight: font-weight('semi-bold');
}

.card__image {
  flex: 0 1 auto;
}
