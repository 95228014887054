/**
 * Mondrian layout
 */

.mondrian {
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
  grid-auto-rows: minmax(200px, auto);
  grid-auto-flow: dense;
}

.mondrian__item {
  display: flex;

  > * {
    flex-grow: 1;
  }
}

@include media-min('sm') {
  .mondrian__item:nth-child(7n+1) {
    grid-column-end: span 2;
    grid-row-end: span 2;
  }

  .mondrian__item:nth-child(7n+2) {
    grid-row-end: span 2;
  }

  .mondrian__item:nth-child(7n+4) {
    grid-column-end: span 2;
  }
}
