/**
 * Tags
 */

.tags {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-left: -0.25em;
  margin-right: -0.25em;

  .tags__item {
    margin: 0.25em;
  }
}

.tags__item {
  display: inline-block;
  padding: $padding-sm;
  color: $primary;
  border: 1px solid rgba($black, 0.1);
  border-radius: $border-radius;
  font-size: $font-size-sm;
  font-family: $font-family-mono;
  text-transform: none;
  letter-spacing: 0;

  &:hover {
    border-color: $primary;
  }

  &.is-active {
    color: $white;
    border-color: $primary;
    background: $primary;
  }
}
