/**
 * Invert Section
 */

.section_invert {
  color: $white;

  .logo {
    @extend .logo_invert;
  }

  .menu {
    @extend .menu_invert;
  }

  .menu_adjacent,
  .menu_siblings,
  .menu_theme_outline {
    @extend .menu_theme_outline-invert;
  }

  .meta {
    @extend .meta_invert;
  }

  .type {
    @extend .type_invert;
  }

  .button_switch {
    @extend .button_color_invert;
  }
}
