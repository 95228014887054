$button-set-spacing: 0.5rem !default;

/**
 * Button Set
 */

.button-set {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: -$button-set-spacing;

  .button {
    margin: $button-set-spacing;
  }
}
