/**
 * Menu overrides
 */

.menu__link {
  &.is-parent {
    background: none;
    color: $primary;
  }
}

.menu_invert {
  .menu__link {
    &.is-parent {
      background: none;
      color: $primary;
    }
  }
}
