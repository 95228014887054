/**
 * Flag
 */

.flag,
.type .flag {
  margin: 4em 0 2em;
  // background: $yellow-100;
  // border: 1px solid $yellow;
  border-top: 1px solid $yellow;
  // border-bottom: 1px solid $yellow;

  > * {
    display: inline-block;
    margin: 0;
    padding: 0.25em 0.75em;
    background: $yellow;
    font-size: $font-size;
  }

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
}
