$menu_spacing: (
  'xs': 1px,
  'sm': 0.25rem,
  'md': 0.5rem,
  'lg': 1rem,
  'xl': 1.5rem
) !default;

/**
 * Menu Spacing Modifier
 */

@each $menu_name, $menu_value in $menu_spacing {

  .menu_spacing_#{$menu_name} {
    .menu__sep {
      margin: 0 $menu_value;
      border-right: $menu__sep-border-width solid $menu__sep-border-color;
      border-top: none;
    }

    .menu__item + .menu__item {
      margin-left: $menu_value;
    }
  }

  .menu_spacing_#{$menu_name}.menu_wrap {
    margin: -($menu_value * 0.5);

    .menu__item,
    .menu__item + .menu__item {
      margin: ($menu_value * 0.5);
    }
  }

  .menu_spacing_#{$menu_name}.menu_stack {
    .menu__sep {
      margin: $menu_value 0;
      border-right: none;
      border-top: $menu__sep-border-width solid $menu__sep-border-color;
    }

    .menu__item + .menu__item {
      margin-top: $menu_value;
      margin-left: 0;
    }
  }

  @each $name, $value in $breakpoints {

    .menu_spacing_#{$menu_name}.menu_stack_#{$name} {
      .menu__sep {
        margin: $menu_value 0;
        border-right: none;
        border-top: $menu__sep-border-width solid $menu__sep-border-color;
      }

      .menu__item + .menu__item {
        margin-top: $menu_value;
        margin-left: 0;
      }
    }

    @include media-min($name) {
      .menu_spacing_#{$menu_name}.menu_stack_#{$name} {
        .menu__sep {
          margin: 0 $menu_value;
          border-right: $menu__sep-border-width solid $menu__sep-border-color;
          border-top: none;
        }

        .menu__item + .menu__item {
          margin-top: 0;
          margin-left: $menu_value;
        }
      }
    }
  }

}
