/**
 * Highlight theme
 */

.menu_theme_highlight {

  .menu__item {
    border-left: 3px solid $shade;

    &:hover {
      border-color: $primary;
    }
  }

  .menu__item + .menu__item {
    margin-top: 5px;
  }

  .menu__link {
    border-radius: 0;
    transition: background-color 1s $transition-timing-function;

    &:hover {
      transition: background-color 0.1s $transition-timing-function;
    }
  }
}
