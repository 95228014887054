/**
 * Table of Contents
 */

.toc,
.toc[data-toggle="off"] {
  .toc__maximize {
    display: block;
  }

  .toc__minimize {
    display: none;
  }
}

.toc[data-toggle="on"] {
  .toc__maximize {
    display: none;
  }

  .toc__minimize {
    display: block;
  }
}

.toc__title {
  @extend .h4;
  margin-bottom: 1rem;
}

.toc__listing {
  list-style: none;

  ul {
    list-style: none;
    margin: 5px 0 0 1rem;
    display: none;
  }

  li.is-toggled > ul,
  li.is-active > ul,
  li.is-parent ul {
    display: block;
  }

  li {
    position: relative;
    padding: 0;
    margin: 0;
    border-left: 3px solid $shade;

    &:hover {
      border-color: darken($shade, 10%);
    }

    &.is-active,
    &.is-parent {
      border-color: $primary;
    }

    &.has-children > a {
      padding-right: 40px;
    }
  }

  li + li {
    margin-top: 5px;
  }

  a {
    display: block;
    padding: $padding;
    border: 1px solid transparent;
  }
}

.toc__toggle {
  position: absolute;
  top: 0;
  right: 0;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-image: url($input_type_select-image);
  background-repeat: no-repeat;
  background-position: center;
  transform: rotate(-90deg);
  transition: transform 0.25s;
  cursor: pointer;

  &:hover {
    background-image: url($input_type_select-image);
    background-repeat: no-repeat;
    background-position: center;
    background-color: rgba($black, 0.05);
  }
}

.is-toggled > .toc__toggle {
  background-color: rgba($black, 0.05);
  transform: rotate(0);
}
