/**
 * Section Theme: Primary
 */

.section_theme_primary {
  @extend .section_invert;
  background: $primary;

  .section__screen {
    background: $primary;
  }
}

/**
 * Section Theme: Secondary
 */

.section_theme_secondary {
  @extend .section_invert;
  background: $secondary;

  .section__screen {
    background: $secondary;
  }
}

/**
 * Section Theme: Accent
 */

.section_theme_accent {
  background: $accent;

  .section__screen {
    background: $accent;
  }
}

/**
 * Section Theme: Shade
 */

.section_theme_shade {
  background: $shade;

  .section__screen {
    background: $shade;
  }
}

/**
 * Section Theme: Dark
 */

.section_theme_dark {
  @extend .section_invert;
  background: $dark;

  .section__screen {
    background: $dark;
  }
}

/**
 * Section Theme: Light
 */

.section_theme_light {
  background: $white;

  .section__screen {
    background: $white;
  }
}
