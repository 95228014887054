/**
 * Slideshow
 */

.slideshow {
  .section__background {
    animation-name: none;
    opacity: 0;
    transition: opacity 2s $transition-timing-function;

    &.is-active {
      opacity: 1;
    }
  }
}
