/**
 * Menu theme outline
 */

.menu_theme_outline {

  .menu__link {
    color: $link-color;
    border-color: rgba($black, 0.1);
    align-items: flex-start;

    &:hover,
    &:focus,
    &.is-active {
      background: $link-color;
      border-color: transparent;
      color: $white;
    }

    &.is-disabled,
    &.is-disabled:hover,
    &.is-disabled:focus {
      background: none;
      color: $color-light;
      border-color: rgba($black, 0.1);
    }
  }
}

.menu_theme_outline-invert {
  @extend .menu_theme_outline;

  .menu__link {
    border-color: rgba($white, 0.25);

    &:hover,
    &:focus,
    &.is-active {
      background-color: $white;
      color: $color;
      border-color: transparent;
    }
  }
}
