/**
 * Content Section
 */

.section_main .container {
  > * + * {
    margin-top: 1.5rem;
  }
}

@include media-min('md') {
  .section_main .container {
    > * + * {
      margin-top: 3rem;
    }
  }
}

/**
 * Layout Styles
 */

.layout-index {
  .section_main {
    background: $shade;
  }
}
