/**
 * Fixed Toolbar
 */

.sticky-toolbar {
  position: fixed;
  z-index: 10;
  display: flex;
  flex-direction: row-reverse;
  padding: 0.5em;
  opacity: 0;
  transition: all $transition-duration $transition-timing-function;

  &.is-active {
    opacity: 1;
    transform: translateX(0);
  }

  > * + * {
    margin: 0 0.5em 0 0;
  }
}

@include media-min('md') {
  .sticky-toolbar {
    padding: 0.75em;
  }
}

/**
 * Tool position modifier
 */

.sticky-toolbar_pos_tr,
.sticky-toolbar_pos_tl {
  transform: translateY(-100%);
}

.sticky-toolbar_pos_br,
.sticky-toolbar_pos_bl {
  transform: translateY(100%);
  flex-direction: row;

  > * + * {
    margin: 0 0 0 0.5em;
  }
}

.sticky-toolbar_pos_tr {
  top: 0;
  right: 0;
}

.sticky-toolbar_pos_br {
  bottom: 0;
  right: 0;
}

.sticky-toolbar_pos_tl {
  top: 0;
  left: 0;
}

.sticky-toolbar_pos_bl {
  bottom: 0;
  left: 0;
}

/**
 * Tool stack modifier
 */

.sticky-toolbar_stack {
  flex-direction: column;

  > * + * {
    margin: 0.5em 0 0 0;
  }
}

.sticky-toolbar_stack.sticky-toolbar_pos_bl,
.sticky-toolbar_stack.sticky-toolbar_pos_br {
  flex-direction: column-reverse;

  > * + * {
    margin: 0 0 0.5em 0;
  }
}
