/**
 * Footer
 */

.footer {
  margin-bottom: 2.5rem;
  color: $color-light;
  @extend .spacing;

  .type {
    margin-top: 2rem;

    &:first-child {
      margin-top: 0;
    }
  }

  .type a {
    @extend .link_subtle;
  }
}
