// Global overrides
// ---

// Typography
$font-family: "Open Sans", Arial, sans-serif;
$heading-font-weight: normal;

// Colors
$primary: #0fce83;
// $primary-light: lighten($primary, 15%);
// $primary-dark: darken($primary, 15%);

// $secondary: $blue-gray;
// $secondary-light: lighten($secondary, 15%);
// $secondary-dark: darken($secondary, 15%);

// $accent: $yellow-400;
// $accent-light: lighten($accent, 15%);
// $accent-dark: darken($accent, 15%);

$shade: lighten($blue-gray-50, 2%);
// $dark: $blue-gray-800;
// $background-color: $gray-100;

// Component Overrides
// ---

// Section
$section__screen-opacity: 0.8;

// Container
$container-max-width: 45rem;
