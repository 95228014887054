/**
 * Background color modifiers
 */

.bg_twitter {
  background: $brand-twitter !important;
}

.bg_facebook {
  background: $brand-facebook !important;
}

.bg_google {
  background: $brand-google !important;
}

.bg_instagram {
  background: $brand-instagram !important;
}

.bg_github {
  background: $brand-github !important;
}

.bg_dribbble {
  background: $brand-dribbble !important;
}

.bg_youtube {
  background: $brand-youtube !important;
}

.bg_pinterest {
  background: $brand-pinterest !important;
}

.bg_linkedin {
  background: $brand-linkedin !important;
}

.bg_yelp {
  background: $brand-yelp !important;
}

.bg_stackoverflow {
  background: $brand-stackoverflow !important;
}

.bg_tumblr {
  background: $brand-tumblr !important;
}

.bg_email {
  background: $brand-email !important;
}

.bg_rss {
  background: $brand-rss !important;
}
