/**
 * Menu share modifier
 */

.menu_social {
  flex-wrap: wrap;
  margin: -1px;
  font-size: 1rem;

  .menu__item,
  .menu__item + .menu__item {
    margin: 1px;
  }

  .menu__link {
    color: $color-light;

    &:hover,
    &:focus {
      background: $link-color;
      color: $white;
    }
  }
}
