/**
 * Menu mini
 */

.menu_mini {
  margin: -1px;
  flex-wrap: wrap;
  flex-direction: row-reverse;
  justify-content: flex-start;

  .menu__item,
  .menu__item + .menu__item {
    margin: 1px;
  }
}
