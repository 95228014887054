/**
 * Hero Section
 */

.section_hero {
  display: flex;
  flex-direction: column;
  background: $shade;
  padding: 0;

  .section {
    position: relative;
    z-index: 3;
  }

  .section__screen {
    background: $shade;
  }
}

.section__header,
.section__footer {
  flex: 0 0 auto;
}

.section__body {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .container {
    width: 100%;
  }
}

/**
 * If both the header and body exist, adjust the top-padding
 */

.section__header + .section__body,
.section__body + .section__footer {
  padding-top: 0;
}

@include media-min('md') {
  .section__header + .section__body {
    padding-top: 1.5em;
  }
}

@include media-min('lg') {
  .section__header + .section__body {
    padding-top: 3em;
  }
}

/**
 * Section Body
 */

.section__body {
  padding: 1.5em;
}

@include media-min('md') {
  .section__body {
    padding: 3em 1.5em;
  }
}

@include media-min('lg') {
  .section__body {
    padding: 6em 1.5em;
  }
}

.section__body .container {
  > * + * {
    margin-top: 0.5rem;
  }

  .space {
    margin-top: 1.5rem;
  }
}

/**
 * Section Merge
 */

.section_merge {
  .section__body {
    padding-bottom: 0.5em;
  }
}

/**
 * Hero Title
 */

.hero__title {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  .h1 {
    flex: 1 1 auto;
  }

  .button {
    flex: 0 0 auto;
    margin-left: 1rem;
  }
}

@include media-min('md') {
  .hero__title {
    align-items: center;
  }
}

/**
 * Hero Intro
 */

.hero__intro {
  font-size: 1.5em;
  line-height: 1.5;
  font-weight: 300;
}

@include media-min('sm') {
  .hero__intro {
    font-size: 1.75em;
  }
}

@include media-min('md') {
  .hero__intro {
    font-size: 2em;
  }
}

@include media-min('lg') {
  .hero__intro {
    font-size: 2.25em;
  }
}

/**
 * Text align center
 */

.text_align_center {
  .hero__title.has-toc {
    text-align: left;
  }
}

@include media-min('xs') {
  .text_align_center {
    .hero__title.has-toc {
      text-align: center;
      padding: 0 (px-to-em(40px) + 1em);

      .button {
        position: absolute;
        right: 0;
      }
    }
  }
}
