/**
 * Menu header
 */

.menu_header {
  margin: -1px;
  flex-wrap: wrap;
  justify-content: flex-end;
  text-transform: uppercase;
  letter-spacing: 1px;

  .menu__item,
  .menu__item + .menu__item {
    margin: 1px;
  }
}
