/**
 * Logo
 */

.logo_text {
  position: relative;
  padding: 0.5em 1em;
  background: $color;
  border: 2px solid $color;
  color: $white;
  text-align: left;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: font-weight('semi-bold');

  &:hover {
    background: none;
    color: $color;
  }
}

.logo_invert.logo_text {
  background: none;
  border-color: $white;
  color: $white;

  &:hover {
    background: $white;
    color: $color;
  }
}

/**
 * Footer Logo
 */

.footer .logo_text {
  background: $color-light;
  border-color: $color-light;

  &:hover {
    background: none;
    color: $color-light;
  }
}
