/**
 * Typography
 */

@include media-min('md') {
  .type {
    line-height: 1.75;
  }
}

/**
 * Align modifier and menus
 */

.text_align_left {
  .button-set,
  .menu,
  .level {
    justify-content: flex-start;
  }
}

.text_align_center {
  .button-set,
  .menu,
  .level {
    justify-content: center;
  }
}

.text_align_right {
  .button-set,
  .menu,
  .level {
    justify-content: flex-end;
  }
}

/**
 * Required asterisk
 */

.req {
  color: $red;
}
