/**
 * Menu Section
 */

.section_menu {
  padding: 1em;
  background: $dark;

  .menu {
    @extend .menu_invert;

    .menu__link.is-disabled,
    .menu__link.is-disabled:hover,
    .menu__link.is-disabled:focus {
      color: $blue-gray-400;
    }
  }

  .menu__link {
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0.5em;
  }
}

@include media-min('xs') {
  .section_menu {
    padding: 1.5em;

    .menu__link {
      padding: 1em;
    }
  }
}

@include media-min('sm') {
  .section_menu {
    .menu__item_next .menu__link {
      flex-direction: row-reverse;

      > * + * {
        margin-left: 0;
        margin-right: 0.5em;
      }
    }
  }
}
