/**
 * Meta
 */

.meta {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  .dropdown {
    display: inline;

    &:hover .dropdown__trigger {
      color: $link-color;
    }
  }

  .dropdown__trigger {
    display: inline-flex;
    align-items: center;
    justify-content: center;

    > * + * {
      margin-left: 0.5em;
    }
  }
}

.meta__item {
  text-transform: uppercase;
  letter-spacing: 1px;
  color: $color-light;

  .dropdown__menu {
    text-transform: none;
    letter-spacing: 0;
  }
}

.meta__sep {
  width: auto;
  height: 1px;
  background-color: rgba($black, 0.1);
  margin: 0.5em 0;
}

@include media-min('sm') {
  .meta {
    flex-direction: row;
  }

  .meta__sep {
    width: 1px;
    height: auto;
    margin: 0 1em;
  }
}

/**
 * Inverted meta
 */

.meta_invert {
  .meta__item {
    color: rgba($white, 0.6);
  }

  .meta__sep {
    background-color: rgba($white, 0.25);
  }
}

/**
 * Alignment modifiers
 */

.text_align_left {
  .meta {
    justify-content: flex-start;
  }
}

.text_align_center {
  .meta {
    justify-content: center;
  }

  .meta .dropdown__menu {
    @extend .dropdown__menu_pos_down;
  }
}

.text_align_right {
  .meta {
    justify-content: flex-end;
  }

  .meta .dropdown__menu {
    @extend .dropdown__menu_pos_down-right;
  }
}
