/**
 * Header
 */

.header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

@include media-min('md') {
  .header {
    align-items: center;
  }
}

.header__item {
  display: flex;
  vertical-align: middle;
}

.header__item + .header__item {
  margin-left: 2em;
}

/* Menu switching */

.menu_header {
  display: none;
}

.menu_mini {
  display: flex;
}

@include media-min('md') {
  .menu_header {
    display: flex;
  }

  .menu_mini {
    display: none;
  }
}
