$lazyload-transition-duration: 1s !default;
$lazyload-transition-timing-function: $transition-timing-function !default;

/**
 * LazyLoad Images
 */

img:not([src]) {
  visibility: hidden;
}

.lazy {
  opacity: 0;

  &.loaded {
    opacity: 1;
    animation-name: fade-in;
    animation-duration: $lazyload-transition-duration;
    animation-timing-function: $lazyload-transition-timing-function;
  }
}
