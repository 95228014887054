/**
 * Menu share modifier
 */

.menu_share {
  justify-content: center;
  flex-wrap: wrap;
  margin: -0.25em;

  .menu__item,
  .menu__item + .menu__item {
    margin: 0.25em;
  }

  .menu__link {
    color: $white;

    &:hover,
    &:focus {
      opacity: 0.7;
    }
  }
}
